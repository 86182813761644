import React from 'react';
import './App.scss'
import { BrowserRouter , Routes, Route} from 'react-router-dom'

//
import Home from './Home';
import BySessionRecords from './BySessionRecords';
import ByTest from './ByTest'

function App() {
  
  return (
    <div className="App">
    <BrowserRouter>
        <header>
          <div></div>
          <div className="header_title">MOONDREAM REALITY GALLERY</div>
          <div className="option_nav"></div>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/session_records" element={<BySessionRecords />} />
          <Route path="/test" element={<ByTest />} />
        </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
