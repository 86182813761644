import React,{useState,useEffect } from 'react'
import { useSearchParams  } from 'react-router-dom'
import axios from "axios"
import FileSaver from 'file-saver'
//component
import SingleItem from './SingleItem'
import HomeList from './HomeList'

//datajson
import dataJson from './data.json'

function ByTest() {
  console.log(dataJson)
  const [loading, setLoading] = useState(false);
  const [dataElement, setDataElement] = useState([]);
  const [videosData, setVieosData] = useState([]);

  const [searchResults, setSearchResults] = useState([]);
  const [isOpen , setIsOpen] = useState(false)
  const [optionSwitch, setOptionSwitch ] = useState(false)
  const [dataType ,setDateType] = useState('photos')
  const [searchParams] = useSearchParams();
  const session_id = searchParams.get('session_id') 
  const urlToken = searchParams.get('token') 
  const handleChoiceData = (dataName)=>{
    if(dataName === 'photos'){
      setDateType('photos')
      // fetchGetData(session_id,urlToken , dataName)
      setDataElement(dataJson['photos'])
    } else if (dataName === 'videos'){
      setDateType('videos')
      setDataElement(dataJson['videos'])
      // fetchGetData(session_id,urlToken,dataName)
    }
    
  }
  const fetchGetData =async (sessison_id,token, dataType)=>{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' , 'Authorization' : `Bearer 6f0a96c3cab50dfcf6edb208014d0741c2cb3883` }      
    };
    await fetch(`https://gallery.moondreamreality.com/api/session_records/${sessison_id}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
            setDataElement(data[dataType])
        });

  }
  const fetchGetDataByDate =async (sessison_id,token, dataType)=>{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' , 'Authorization' : `Download ${token}` }      
    };
    await fetch(`https://gallery.moondreamreality.com/api/session_records_by_date?date=2022-03-31`, requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
            setDataElement(data[dataType])
        });

  }
  const handleAddClick = (dataId) => {

    // 根據ID找大圖
    const results  =   dataJson['photos'].find((d)=>{
      return d.id === dataId
    })
    setSearchResults(results)

    setIsOpen(!isOpen)
  };
  const handleOpen = () => {
    setIsOpen(!isOpen)
  }
  const getFile = url => new Promise((resolve,reject)=>{
    axios({
      method: 'GET',
      url,
      responseType:'blob',
      crossOrigin:true,
      withCredentials:true
    }).then((data)=>{
      resolve(data.data)
    }).catch((error)=>{
      reject(error.toString())
    })
  })

  
  const handleDownload = (url) =>{
    console.log('ckick')
    console.log(url)
    const targetURL = `${process.env.PUBLIC_URL}/assets/test.mp4'`

    getFile(url).then((data)=>{
      FileSaver.saveAs(data)
    }).catch((e)=>{
      console.log(e)
    })


  }

  const  downloadURI =(uri, name) =>{

  }

  useEffect(() => {
    setDataElement(dataJson['photos'])
    // fetchGetData(session_id,urlToken,'photos')
  },[])
  return (
    <div>
      <div className="nav">
        <div className={dataType === 'photos' ? 'active' : ''} onClick={()=> handleChoiceData('photos')}>Images</div>
        <div className={dataType === 'videos' ? 'active' : ''} onClick={()=> handleChoiceData('videos')}>Videos</div>
      </div>

      {
        isOpen ?  <SingleItem data={searchResults} handler={handleOpen} visible={isOpen}  dataType={dataType}/> : null
      }
      {
        dataElement ? <HomeList data={dataElement} handler={handleAddClick} dataType={dataType} urlToken={urlToken}/> : 'loading'
      }
      <button className='f:28 hidden ' onClick={()=>handleDownload(`/Assets/test.mp4`)}>下載</button>
      <a href="/Assets/test.mp4" className='f:28 f:white hidden ' download={true}>下載</a>


    </div>
  )
}

export default ByTest