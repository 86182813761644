import React from 'react'
import { FaImage, FaVideo, FaRegCircle, FaRegCheckCircle } from "react-icons/fa";
function HomeList({data,handler, dataType ,urlToken}) {
  const handleClick= (dataId) =>{
    console.log(dataId)
    handler(dataId)
  }
  const {photos , videos} = data
  return (
    <div className="w:11/12  mx:auto w:5/6@2xs ">
        <div className=" flex gap:10 flex:wrap justify-content:space-between ">
        {
          
          data && dataType === 'photos' ?  
            data.map((item)=>
              {
                const {id, image_url,thumb_url} = item
                return(
              
                  <div 
                    key={id} 
                    className=" flex-basis:30% width:30% pb:30%  aspect:1/1 rel bg:center bg:no-repeat bg:cover " onClick={()=> handleClick(id)}
                    style={{
                      backgroundImage: `url("${thumb_url? thumb_url : 'https://picsum.photos/200'}")`
                    }}
                  >
                    <div className="leftIcons abs left:5% bottom:5%">
                      {dataType === 'photos' ? <FaImage color='white' /> : <FaVideo color='white'/>}
                    </div>
                    <div className="circleCheck">
                      {/* <FaRegCircle /> */}
                    </div>
                  
                  </div>
                )
              }
            )
          :
          data.map((item)=>{
            const {id, image_url , thumb_url} = item
            return(
          
              <div 
                key={id} 
                className="flex-basis:30% width:30% pb:30%  aspect:1/1 rel bg:center bg:no-repeat bg:cover" onClick={()=> handleClick(id)}
                style={{
                  backgroundImage: `url("${thumb_url? thumb_url : 'https://picsum.photos/200'}")`
                }}>
              
                <div className="leftIcons abs left:5% bottom:5%">
                  {dataType === 'photos' ? <FaImage color='white'/> : <FaVideo color='white'/>}
                </div>
              
              </div>
            )
           })
          
        }
        </div>
      </div>
  )
}

export default HomeList