import React, {useEffect, useState} from 'react'
import ReactPlayer from 'react-player'
import { FaTimes } from "react-icons/fa";
import axios from "axios"
import FileSaver from 'file-saver'
import {browserName,isMobile,isIOS,isAndroid, isSafari,isChrome,isFirefox} from 'react-device-detect';
function SingleItem({data,handler,dataType}) {
//  alert(isChrome)
  const [itemActive , setItemActive] = useState(false)
  
  const handleClick= () =>{
    setItemActive(!itemActive)
    setTimeout(() => {
      handler()
    }, 0);
    // handler()
  }  
  function isWebview() {
      if(isSafari) return false
      if(isChrome) return false
      if(isFirefox) return false
      var useragent = navigator.userAgent;
      var rules = ['WebView','(iPhone|iPod|iPad)(?!.*Safari\/)','Android.*(wv|\.0\.0\.0)'];
      var regex = new RegExp(`(${rules.join('|')})`, 'ig');
      return Boolean(useragent.match(regex));
  }
  // alert(isWebview())
  const getFile = url => new Promise((resolve,reject)=>{
    axios({
      method: 'GET',
      url,
      responseType:'blob'
    }).then((data)=>{
      resolve(data.data)
    }).catch((error)=>{
      reject(error.toString())
    })
  })

  //如果是ios chrome無法下載影片 跳出訊息說請用safari
  //測試安卓下載影片  圖片
  const handleDownload = (url) =>{
    console.log('url:',url)
    var timeInMs = Date.now();
    if(isWebview() ){
      alert('這個瀏覽器不支援下載檔案，請嘗試使用 Safari 下載。')
      return
    }
    if(isIOS && !isSafari ){
      alert('這個瀏覽器不支援下載檔案，請嘗試使用 Safari 下載。')
    } else {
      // getFile(url).then((data)=>{
      //   FileSaver.saveAs(data)
      // }).catch((e)=>{
      //   console.log(e)
      //   alert(e,'此影片無法下載')
      // })
      var newUrl = url.replace('https://expo.moondreamreality.com','');
      console.log(':',newUrl)
      var element = document.createElement('a');
      element.setAttribute('href', newUrl);
      element.setAttribute('download',`download_video_${timeInMs}`);
      element.style.display = 'none';
      document.body.appendChild(element);
  
      element.click();
  
      document.body.removeChild(element);
    }


  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setItemActive(!itemActive)
    }, 350);
    return () => clearTimeout(timer);
  },[]);
  return (
    <>
    {
      dataType === 'photos' ? 
      <div className="singleItem">
        <div className={itemActive ? "blackbg active" : "blackbg"} onClick={handleClick}></div>
        <div className={'fixed top:50% left:50%  w:full w:4/6@2xs translate(-50%,-50%) ~duration:300ms ' + (itemActive ? `opacity:1 ` : ' opacity:0 ' )}>
          
          <div className='w:full w:4/6@2xs mx:auto@2xs rel z:10'>
            <div className='abs top:20px right:20px border rounded p:8  bg:black cursor:pointer z:40' onClick={handleClick}><FaTimes color='white'/></div>
            <img src={`${data.image_url ? data.image_url  : 'https://picsum.photos/100/100'}`} alt="" className='w:full' onTouchStart={(e)=>e.preventDefault} />
            {
              isAndroid && isWebview() && 
              <div className='f:gray-80 t:center mt:5'>使用預設瀏覽器開啟可下載圖片</div>
            }
          </div>
          
        </div>
      </div>
      : 
      <div className="singleItem">
        <div className={itemActive ? "blackbg active" : "blackbg"} onClick={handleClick}></div>
        <div className={'fixed top:50% left:50%  w:full w:4/6@2xs translate(-50%,-50%) ~duration:300ms ' + (itemActive ? `opacity:1 ` : ' opacity:0 ' )}>
            <div className='abs top:20px right:20px border rounded p:8  bg:black cursor:pointer z:40' onClick={handleClick}><FaTimes color='white'/></div>
            <ReactPlayer 
              url={`${data.video_url}`}
              width= "100%"
              height= "100%"
              controls={true}
              volume={0.4}
            />
            {isMobile && !isAndroid &&
              <div className='flex jc:center pt:4'>
                <button className='bg:white f:black rounded px:10 py:5 f:14 ' onClick={()=>handleDownload(data.video_url)}>按此下載影片到手機</button>
                <a href='/api/md_videos/b9e5a7ff-e2bd-4b21-899e-47af7c1af491/video.mp4' download={true} className='bg:white f:black rounded px:10 py:5 f:14 hidden' >a 按此下載影片到手機</a>
              
              </div>
            }
            {
              isAndroid && isWebview() &&
              <div className='f:gray-80 t:center mt:5'>使用預設瀏覽器開啟可下載影片</div>
            }

            
           
        </div>
      </div>
    }
    </>

    
  )
}

export default SingleItem