import React,{useState,useEffect } from 'react'
import { useSearchParams  } from 'react-router-dom'

//component
import SingleItem from './SingleItem'
import HomeList from './HomeList'

function BySessionRecords() {
  const [dataElement, setDataElement] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isOpen , setIsOpen] = useState(false)
  const [dataType ,setDateType] = useState('photos')
  const [searchParams] = useSearchParams();
  const start = searchParams.get('start') 
  const end = searchParams.get('end') 
  const urlToken = searchParams.get('token') 
  const fetchGetDataByRange =async (start, end, token, dataType)=>{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' , 'Authorization' : `Download ${urlToken}` }      
    };

    await fetch(`https://gallery.moondreamreality.com/api/session_records_by_range?start=${start}&end=${end}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data)
            setDataElement(data[dataType])
        });

  }
  const handleChoiceData = (dataName)=>{
    if(dataName === 'photos'){
      setDateType('photos')
      fetchGetDataByRange(start,end,urlToken , dataName)
    } else if (dataName === 'videos'){
      setDateType('videos')
      fetchGetDataByRange(start,end,urlToken,dataName)
    }
    
  }
  const handleAddClick = (dataId) => {

    // 根據ID找大圖
    const results  =   dataElement.find((d)=>{
      return d.id === dataId
    })
    setSearchResults(results)

    setIsOpen(!isOpen)
  };
  const handleOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    fetchGetDataByRange(start, end, urlToken,'photos')
  },[start, end,urlToken])

  return (
    <div> 
      <div className="nav">
        <div className={dataType === 'photos' ? 'active' : ''} onClick={()=> handleChoiceData('photos')}>Images</div>
        <div className={dataType === 'videos' ? 'active' : ''} onClick={()=> handleChoiceData('videos')}>Videos</div>
      </div>

    {
      isOpen ?  <SingleItem data={searchResults} handler={handleOpen} visible={isOpen}  dataType={dataType}/> : null
    }
    {
      dataElement.length > 0 ? <HomeList data={dataElement} handler={handleAddClick} dataType={dataType} urlToken={urlToken}/> : 'loading'
    }

    </div>
  )
}

export default BySessionRecords